<template>
  <div class="layout-client-portals">
    <div
      v-for="(item, index) in portals"
      :key="index"
      class="layout-client-portals__item"
    >
      <a
        v-if="item.productUrl"
        :href="`//${item.productUrl}`"
        target="_blank"
      />

      <img
        v-if="item.imageUrl"
        class="layout-client-portals__item-icon"
        :src="item.imageUrl"
        :alt="item.name"
        @error="onImageError"
      >

      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import brokenImageMixin from '@/mixins/broken-image';

export default {
  name: 'LayoutSidebarPortals',

  mixins: [brokenImageMixin],

  computed: {
    ...mapGetters('system', ['portals']),
  },
};
</script>

<style lang="scss">
.layout-client-portals {
  width: 202px;
  padding: 16px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    text-transform: uppercase;

    @include for-size(phone-portrait-down) {
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: 16px;

      @include for-size(phone-portrait-down) {
        margin-bottom: 24px;
      }
    }

    &-icon {
      display: inline-block;
      width: auto;
      height: auto;
      max-width: 24px;
      max-height: 24px;
      margin-right: 10px;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
