<template>
  <fragment>
    <esmp-sidebar
      v-model="isSidebarCollapsed"
      class="sidebar"
      :view-mode="sidebarViewMode"
      page-wrapper-selector="#app"
    >
      <template #additional>
        <esmp-button
          v-if="sidebarMenuLocal.length && !isAdminPageOpened"
          class="sidebar__settings-button"
          view="function"
          icon="24-settings"
          title="Включить режим сортировки"
          @click.stop="toggleMenuSortMode"
        />
      </template>

      <template #menu>
        <draggable
          v-model="sidebarMenuLocal"
          class="sidebar__items-wrapper"
          handle=".sidebar__item--draggable"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          :name="!drag ? 'flip-list' : null"
        >
          <esmp-sidebar-link
            v-for="(link, index) in sidebarMenuLocal"
            :key="index"
            :class="[
              'sidebar__item',
              {
                'sidebar__item--draggable': isMenuSortMode,
              }
            ]"
            :tag-label="getMenuItemCounter(link.counter)"
            :icon="link.icon"
            :label="link.label"
            :is-active="$route.path === link.url"
            :link="isMenuSortMode ? null : link.url"
            @click="isMenuSortMode ? void 0 : runAction(link.action)"
          />
        </draggable>
      </template>

      <template #pre-footer>
        <esmp-sidebar-link
          v-if="!isAdminPageOpened && adminLink"
          class="sidebar__item"
          :icon="adminLink.icon"
          :label="adminLink.label"
          :is-active="false"
          :link="adminLink.url"
        />
        <esmp-sidebar-contacts :contacts="contactInfo" />
      </template>

      <template #footer>
        <esmp-dropdown
          v-if="portals && portals.length"
          :transfer="true"
          :trigger="portalsDropdownTrigger"
        >
          <esmp-sidebar-link icon="dots">
            <template v-if="currentPortal" #label>
              <span class="sidebar-portal-name">
                <img
                  v-if="currentPortal.imageUrl"
                  class="sidebar-portal-name__logo"
                  :src="currentPortal.imageUrl"
                  :alt="currentPortal.name"
                >

                <span>{{ currentPortal.name }}</span>
              </span>
            </template>
          </esmp-sidebar-link>
          <template #list>
            <layout-sidebar-portals />
          </template>
        </esmp-dropdown>

        <esmp-sidebar-link
          v-if="loggedInUser"
          :label="`${loggedInUser.name} ${loggedInUser.lastName}`"
          :is-active="isAccountPageOpened"
          :link="isAccountOrAdminPageOpened ? '/logout' : '/account'"
        >
          <template #icon>
            <esmp-user-avatar
              v-if="!isAccountOrAdminPageOpened"
              :username="loggedInUser.fullName"
              :image-url="loggedInUser.photoURL"
              :size="24"
            />
            <esmp-icon name="logout" v-if="isAccountOrAdminPageOpened" />
          </template>
        </esmp-sidebar-link>
      </template>
    </esmp-sidebar>

    <modal-chat-bot-registration v-model="isChatBotRegistrationModalShowed" />
  </fragment>
</template>

<script>
/*
 TODO: сделать проверку на активность пункта миню как в lingvo
*/
import draggable from 'vuedraggable';
import { Fragment } from 'vue-fragment';
import { constants as EsmpUIConstants } from '@esmpfrontend/ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import ModalChatBotRegistration from '@/components/modals/modal-chat-bot-registration';
import LayoutSidebarPortals from './LayoutSidebarPortals.vue';

export default {
  name: 'LayoutSidebar',

  components: {
    ModalChatBotRegistration,
    LayoutSidebarPortals,
    Fragment,
    draggable,
  },

  data() {
    return {
      isChatBotRegistrationModalShowed: false,
      isMenuSortMode: false,
      drag: false,
      dragOptions: Object.freeze({
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      }),
    };
  },

  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapState('system', ['sidebarMenu', 'delayedLinkAction']),
    ...mapGetters('system', ['currentPortal', 'contactInfo', 'portalLogo', 'portals']),
    ...mapState('user', ['loggedInUser']),
    ...mapGetters('user', ['isRequestFromMode']),
    ...mapState('viewOptions', ['windowSizes']),
    ...mapState('ticket', ['counters']),

    adminLink() {
      return this.sidebarMenu?.adminLink;
    },

    sidebarMenuLocal: {
      get() {
        if (this.isAdminPageOpened) {
          return this.sidebarMenu.adminMenu;
        }
        return this.sidebarMenu?.portalMenu?.filter(
          (link) => (this.isRequestFromMode ? link.isShownThenRequestFrom && link.visibility : link.visibility),
        );
      },

      async set(value) {
        try {
          const portalMenu = [...new Set([...value, ...this.sidebarMenu.portalMenu])];
          const sidebarMenu = {
            ...this.sidebarMenu,
            portalMenu,
          };
          await this.updateSidebarMenu({ menuType: 'portalMenu', newValue: sidebarMenu });

          this.$EsmpNotify.$show('Порядок пунктов меню успешно изменен!', 'success');
        } catch {
          this.$EsmpNotify.$show(
            'Возникла проблема при изменении порядка пунктов меню. Обратитесь к администратору',
            'error',
          );
        }
      },
    },
    isAdminPageOpened() {
      return this.$route.path.startsWith('/admin');
    },
    isAccountPageOpened() {
      return this.$route.path.startsWith('/account');
    },
    isAccountOrAdminPageOpened() {
      return this.isAdminPageOpened || this.isAccountPageOpened;
    },
    isSidebarCollapsed: {
      get() {
        return this.isCollapsedMenu;
      },

      set(value) {
        this.collapseMenu(value);
      },
    },

    sidebarViewMode() {
      if (this.windowSizes.isPhonePortrait) {
        return EsmpUIConstants.SIDEBAR_VIEW_MODES.fullPage;
      }

      if (this.windowSizes.isTabletPortrait) {
        return EsmpUIConstants.SIDEBAR_VIEW_MODES.float;
      }

      return EsmpUIConstants.SIDEBAR_VIEW_MODES.default;
    },

    portalsDropdownTrigger() {
      return this.portals?.length < 2 ? 'custom' : 'hover';
    },
  },

  watch: {
    isSidebarCollapsed: {
      immediate: true,

      handler(value) {
        // скрытие режима сортировки при закрытии сайдбара
        if (value) this.isMenuSortMode = false;
      },
    },

    delayedLinkAction: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.runAction(this.delayedLinkAction);
            this.clearDelayedLinkAction();
          });
        }
      },
    },
  },

  mounted() {
    // TODO: возможно переделать так, чтобы этот запрос выполнялся периодически
    if (!this.isAdminPageOpened) this.fetchCounters();
  },

  methods: {
    ...mapActions('sidebar', ['collapseMenu']),
    ...mapActions('ticket', ['fetchCounters']),
    ...mapActions('system', ['clearDelayedLinkAction', 'updateSidebarMenu']),

    runAction(method) {
      switch (method) {
      case 'openChatBotRegistration':
        // open chat bot registration modal
        this.isChatBotRegistrationModalShowed = true;
        break;
      default:
        break;
      }
    },

    toggleMenuSortMode() {
      this.isMenuSortMode = !this.isMenuSortMode;
    },

    getMenuItemCounter(counterName) {
      return this.counters[counterName] && !this.isRequestFromMode
        ? String(this.counters[counterName])
        : null;
    },
  },
};
</script>

<style lang="scss">
.esmp-sidebar {
  z-index: 11;

  .esmp-dropdown {
    display: unset;
  }
}

.esmp-sidebar-link {
  .esmp-user-avatar {
    width: 24px;
    height: 24px;
  }
}

.sidebar-portal-name {
  display: flex;
  align-items: center;

  &__logo {
    display: inline-block;
    margin-right: 10px;
    width: auto;
    height: auto;
    max-width: 20px;
    max-height: 20px;
  }

  span {
    text-transform: uppercase;
  }
}

.sidebar {
  $sidebarClass: &;
  $sidebar-item-draggable-element-width: 40px;

  &__settings-button {
    color: inherit;

    &:hover {
      color: $primary-color-hover;
    }
  }

  &__item {
    transition:
      width $base-animation,
      margin-left $base-animation,
      background-color $base-animation,
      color $base-animation;

    &:before {
      content: '=';
      position: absolute;
      top: 0;
      left: -$sidebar-item-draggable-element-width;
      width: $sidebar-item-draggable-element-width;
      height: $esmp-sidebar-link-height;
      line-height: $esmp-sidebar-link-height;
      background: inherit;
      font-size: 26px;
      font-weight: 400;
      text-align: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity $base-animation;
    }

    &--draggable {
      width: calc(100% - #{$sidebar-item-draggable-element-width});
      margin-left: $sidebar-item-draggable-element-width;
      position: relative;
      cursor: move;
      background: none;
      color: $esmp-sidebar-link-color;

      &:before {
        opacity: 1;
        visibility: visible;
      }

      &:hover {
        background: $esmp-sidebar-background-color;
        color: $esmp-sidebar-link-color;
      }
    }
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: $esmp-sidebar-link-active-background-color;
  }
}
</style>
